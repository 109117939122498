import * as React from 'react';
import CardMui from '@mui/material/Card';

import Collapse from'../collapse'
import{CardContent,CardActions,CardHeader,ICardProps,ICard}from'.'


const Card:ICard=React.forwardRef((props:ICardProps,ref)=>{
  // const _refs={
  //   header  :React.useRef(),
  //   actions :React.useRef(),
  //   content :React.useRef(),
  //   card    :React.useRef(),
  //   collapse:React.useRef()
  // }

  // const _refs={
  //   header  :null,
  //   actions :null,
  //   content :null,
  //   card    :null,
  //   collapse:null
  // }


  // const[_refs,set_Refs]=React.useState({
  //   header  :null,
  //   actions :null,
  //   content :null,
  //   card    :null,
  //   collapse:null
  // })

  // const _setRef=key=>hooks.setRef(key,set_Refs)


  const _Children={
    content:<>
      {props?.children||props?.content?.children||'no kids'}
      {props?.actionsInContent&&props?.actions?.children}
    </>
  }

  // const _Header  =<CardHeader   {...props?.header  } ref={_setRef('header')}/>
  // const _Actions =<CardActions  {...props?.actions } ref={_setRef('actions')}/>
  // const _Content =<CardContent  {...props?.content } children={_Children.content} ref={_setRef('content' )}/>
  // const _Collapse=<Collapse     {...props?.collapse} ref={_setRef('collapse')}/>
  // const _Card    =<CardMui      {...props?.card    } ref={_setRef('card')}>
  //   {props?.header?.children&&_Header}
  //   {_Content}
  //   {(props?.actions?.children&&!props?.actionsInContent)&&_Actions}
  //   {props?.collapse?.children&&_Collapse}
  // </CardMui>


  const _Header  =<CardHeader   {...props?.header  } title={props?.header?.children||props?.header?.title}/>
  const _Actions =<CardActions  {...props?.actions }/>
  const _Content =<CardContent  {...props?.content } children={_Children.content}/>
  const _Collapse=<Collapse     {...props?.collapse}/>
  const _Card    =<CardMui      {...props?.card    }>
    {props?.header&&Object.keys(props?.header)&&_Header}
    {_Content}
    {(props?.actions?.children&&!props?.actionsInContent)&&_Actions}
    {props?.collapse?.children&&_Collapse}
  </CardMui>


  // React.useImperativeHandle(ref,()=>({
  //   ..._refs,
  //   // components:{
  //   //   header:_Header,
  //   //   actions:_Actions,
  //   //   content:_Content,
  //   //   collapse:_Collapse,
  //   //   card:_Card
  //   // }
  //   // refCollapse
  // }))



  return _Card
})

Card.defaultProps={
  actionsInContent:false
}

// Card.content=React.forwardRef((props:ICardContentProps,ref)=>{
//   console.log('content',props)
//   return <CardContent {...props} ref={ref}/>
// })
// Card.actions=React.forwardRef((props:ICardActionsProps,ref)=><CardActions {...props} ref={ref}/>)
// Card.header =React.forwardRef((props:ICardHeaderProps,ref )=><CardHeader {...props} ref={ref}/>)

export default Card

// export default function Card({ref,card,header,media,content,actions,collapse}):React.Element<typeof CardMui>{
//   const[expanded,setExpanded]=React.useState(false);

//   React.useImperativeHandle(ref,()=>({
//     useState:[expanded,setExpanded]
//   }))

//   // const toggleExpand=()=>setExpanded(o=>!o)

//   // const refState=React.useRef(null)
//   // refState.current={expanded:[expanded,setExpanded]}

//   // React.useEffect(()=>{if(collapse?.withState!=null){setExpanded(collapse.withState)}},[collapse?.withState])

//   // React.useEffect(()=>{ifFn(collapse?.extractStateOnInit,refState.current)},[])

//   // React.useEffect(()=>{ifFn(collapse?.onStateChange,refState.current)},[collapse?.onStateChange,refState.current])


//   const handleToggleExpand = (e) => {
//     const state={expanded,setExpanded}
//     ifFn(collapse?.button?.onClick,{e,state,arguments})
//     setExpanded(!expanded);
//   };

//   const Components={
//     card    :card?._component||CardMui,
//     header  :header ?._component||CardHeader,
//     // media   :media  ?._component||CardMedia,
//     content :content?._component||CardContent,
//     actions :actions?._component||CardActions,
//     collapseButton :collapse?.button?._component||Button,
//     collapseButtonChildren :collapse?.button?.children||$ExpandIcon,
//     collapse:collapse?._component||Collapse
//   }

//   const collapseButtonProps={
//     expand:expanded.toString(),
//     ...collapse?.button,
//     onClick:handleToggleExpand,
//     'aria-expanded':expanded,
//     'aria-label':"show more",
//   }

//   const _CollapseButton=<Components.collapseButton
//     expand={expanded.toString()}
//     {...collapse?.button}
//     children={<Components.collapseButtonChildren expand={expanded.toString()}/>}
//     onClick={handleToggleExpand}
//     aria-expanded={expanded.toString()} aria-label="show more"
//   />

//   const actionsChildren=<>
//     {actions?.children}
//     {collapse?.children?_CollapseButton:null}
//   </>

//   const _Header  =<Components.header   {...header  }/>
//   // const _Media   =<Components.media    {...media   }/>
//   const _Content =<Components.content  {...content }/>
//   const _Actions =<Components.actions  {...actions } children={actionsChildren}/>
//   const _Collapse=<Components.collapse {...collapse} in={expanded}/>
//   const _Card    =<Components.card {...card}>
//       {header?.children&&_Header}
//       {/* {_Media} */}
//       {content?.children&&_Content}
//       {actions?.children&&_Actions}
//       {_Collapse}
//   </Components.card>

//   return _Card
// }



// export default function Card({card,header,media,content,actions,collapse})=>{
//   const[expanded,setExpanded]=React.useState(false);

//   React.useImperativeHandle(ref,()=>({
//     useState:[expanded,setExpanded]
//   }))

//   // const toggleExpand=()=>setExpanded(o=>!o)

//   // const refState=React.useRef(null)
//   // refState.current={expanded:[expanded,setExpanded]}

//   // React.useEffect(()=>{if(collapse?.withState!=null){setExpanded(collapse.withState)}},[collapse?.withState])

//   // React.useEffect(()=>{ifFn(collapse?.extractStateOnInit,refState.current)},[])

//   // React.useEffect(()=>{ifFn(collapse?.onStateChange,refState.current)},[collapse?.onStateChange,refState.current])


//   const handleToggleExpand = (e) => {
//     const state={expanded,setExpanded}
//     ifFn(collapse?.button?.onClick,{e,state,arguments})
//     setExpanded(!expanded);
//   };

//   const Components={
//     card    :card?._component||CardMui,
//     header  :header ?._component||CardHeader,
//     // media   :media  ?._component||CardMedia,
//     content :content?._component||CardContent,
//     actions :actions?._component||CardActions,
//     collapseButton :collapse?.button?._component||Button,
//     collapseButtonChildren :collapse?.button?.children||$ExpandIcon,
//     collapse:collapse?._component||Collapse
//   }

//   const collapseButtonProps={
//     expand:expanded.toString(),
//     ...collapse?.button,
//     onClick:handleToggleExpand,
//     'aria-expanded':expanded,
//     'aria-label':"show more",
//   }

//   const _CollapseButton=<Components.collapseButton
//     expand={expanded.toString()}
//     {...collapse?.button}
//     children={<Components.collapseButtonChildren expand={expanded.toString()}/>}
//     onClick={handleToggleExpand}
//     aria-expanded={expanded.toString()} aria-label="show more"
//   />

//   const actionsChildren=<>
//     {actions?.children}
//     {collapse?.children?_CollapseButton:null}
//   </>

//   const _Header  =<Components.header   {...header  }/>
//   // const _Media   =<Components.media    {...media   }/>
//   const _Content =<Components.content  {...content }/>
//   const _Actions =<Components.actions  {...actions } children={actionsChildren}/>
//   const _Collapse=<Components.collapse {...collapse} in={expanded}/>
//   const _Card    =<Components.card {...card}>
//       {header?.children&&_Header}
//       {/* {_Media} */}
//       {content?.children&&_Content}
//       {actions?.children&&_Actions}
//       {_Collapse}
//   </Components.card>

//   return _Card
// }



// const Card=React.forwardRef(({card,header,media,content,actions,collapse},ref):React.ElementRef<typeof CardMui>=>{
//   const[expanded,setExpanded]=React.useState(false);

//   React.useImperativeHandle(ref,()=>({
//     useState:[expanded,setExpanded]
//   }))

//   // const toggleExpand=()=>setExpanded(o=>!o)

//   // const refState=React.useRef(null)
//   // refState.current={expanded:[expanded,setExpanded]}

//   // React.useEffect(()=>{if(collapse?.withState!=null){setExpanded(collapse.withState)}},[collapse?.withState])

//   // React.useEffect(()=>{ifFn(collapse?.extractStateOnInit,refState.current)},[])

//   // React.useEffect(()=>{ifFn(collapse?.onStateChange,refState.current)},[collapse?.onStateChange,refState.current])


//   const handleToggleExpand = (e) => {
//     const state={expanded,setExpanded}
//     ifFn(collapse?.button?.onClick,{e,state,arguments})
//     setExpanded(!expanded);
//   };

//   const Components={
//     card    :card?._component||CardMui,
//     header  :header ?._component||CardHeader,
//     // media   :media  ?._component||CardMedia,
//     content :content?._component||CardContent,
//     actions :actions?._component||CardActions,
//     collapseButton :collapse?.button?._component||Button,
//     collapseButtonChildren :collapse?.button?.children||$ExpandIcon,
//     collapse:collapse?._component||Collapse
//   }

//   const collapseButtonProps={
//     expand:expanded.toString(),
//     ...collapse?.button,
//     onClick:handleToggleExpand,
//     'aria-expanded':expanded,
//     'aria-label':"show more",
//   }

//   const _CollapseButton=<Components.collapseButton
//     expand={expanded.toString()}
//     {...collapse?.button}
//     children={<Components.collapseButtonChildren expand={expanded.toString()}/>}
//     onClick={handleToggleExpand}
//     aria-expanded={expanded.toString()} aria-label="show more"
//   />

//   const actionsChildren=<>
//     {actions?.children}
//     {collapse?.children?_CollapseButton:null}
//   </>

//   const _Header  =<Components.header   {...header  }/>
//   // const _Media   =<Components.media    {...media   }/>
//   const _Content =<Components.content  {...content }/>
//   const _Actions =<Components.actions  {...actions } children={actionsChildren}/>
//   const _Collapse=<Components.collapse {...collapse} in={expanded}/>
//   const _Card    =<Components.card {...card}>
//       {header?.children&&_Header}
//       {/* {_Media} */}
//       {content?.children&&_Content}
//       {actions?.children&&_Actions}
//       {_Collapse}
//   </Components.card>

//   return _Card
// })

// export default Card
