import * as React from 'react';
import CollapseMui from '@mui/material/Collapse';

import{ICollapseProps}from'./types';


const Collapse=React.forwardRef((props:ICollapseProps,ref)=>{
  const[_isExpanded,set_IsExpanded]=React.useState(()=>props?.in||true)

  const toggleIsExpanded=React.useCallback(()=>set_IsExpanded(o=>!o),[])

  const _Collapse=<CollapseMui in={_isExpanded} unmountOnExit={false} {...props} ref={ref}/>

  Collapse.toggleIsExpanded=toggleIsExpanded


  // React.useImperativeHandle(ref,()=>({
  //   useState:[isExpanded,setIsExpanded],
  //   toggleIsExpanded,
  //   getIsExpanded:()=>{return isExpanded},
  // }),[isExpanded])

  // Collapse.toggleIsExpanded=toggleIsExpanded

  return _Collapse
})

export default Collapse
