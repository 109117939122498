import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import axios from 'axios'

import MenuItem from '@mui/material/MenuItem';
import Typo from'@mui/material/Typography';
import TextField,{TextFieldProps} from'@mui/material/TextField'

import Button from'hendrixmaterial/button'
import Box from'hendrixmaterial/box'
import Card from'hendrixmaterial/card'
import * as Progress from'hendrixmaterial/progress'


const postLoc="https://jhlm.pattyhendrix.com/predict"


const valsNwords  =Array.from(Array(9)).map((x, index) => 10+index*10)
const valsVariance=Array.from(Array(10)).map((x, index) => (index+1)/10)


const _values={
  sentence:'this is really cool because',
  words:40,
  variance:'0.2'
}


const formSchema=Yup.object({
  sentence: Yup.string()
    .max(40, 'Max 40 characters'),
  words: Yup.number()
    .min(10,  'Min: 10')
    .max(200, 'Max: 200'),
  variance: Yup.number()
    .min(0, 'Must be between 0-1')
    .max(1, 'Must be between 0-1')
})


const makeField=(props:TextFieldProps)=><TextField
  type='text'
  color='secondary'
  fullWidth
  variant='outlined'
  size='small'
  margin='dense'
  InputLabelProps={{shrink:true}}
  {...props}
/>


const _fields:[TextFieldProps]=[
  {
    name:"sentence",
    placeholder:'this is really cool because',
    label:"start of sentence",
    // defaultValue:_values.sentence,
  },
  {
    name:"words",
    label:"# of words",
    select:true,
    children:valsNwords.map(n=><MenuItem key={n} value={n} children={n}/>),
    // defaultValue:_values.words
  },
  {
    name:"variance",
    label:"variance in word generation",
    select:true,
    children:valsVariance.map(n=><MenuItem key={n} value={n} children={`${n*100}%`}/>),
    // defaultValue:_values.variance
  }
]


export default function Index(){
  const[data  ,setData  ]=React.useState(null)
  const[err   ,setErr   ]=React.useState(null)


  const formik = useFormik({
    initialValues: _values,
    validationSchema: formSchema,
    onSubmit:handleSubmit
  });


  function handleSubmit(vals){
    setErr(null)
    setData(null)
    // console.log('onSubmit values',vals)

    var fd = new FormData();
    fd.append("sent"  ,vals.sentence)
    fd.append("nwords",vals.words)
    fd.append("temp"  ,vals.variance)


    axios.post(postLoc, fd,{
      // onUploadProgress:progressEvent=>{
      //   var progress=`Progress: ${Math.round((progressEvent.loaded/progressEvent.total)*100)}%`;
      //   // console.log(progress)
      // }
    })
    .then(res=>{
      setData(res.data.predictions)
      // console.log(data)
      // setSubmitting(false)
      formik.setSubmitting(false)
    })
    .catch(err=>{
      // setSubmitting(false)
      setErr(err.message)
      formik.setSubmitting(false)
    })
  }


  const _card=<Card

    header={{
      children:<Typo variant='h5'>Jeremy Howard Language Model</Typo>,
      subheader:<Typo>
        Talk deep learning like the real <a href="https://en.wikipedia.org/wiki/Jeremy_Howard_(entrepreneur)" target="_blank" rel="noopener noreferrer">Jeremy Howard</a>.
        Made with <a href="https://github.com/fastai/fastai" target="_blank" rel="noopener noreferrer">fastai</a>
      </Typo>
    }}

    children={_fields.map((o,i)=><React.Fragment key={i}>
      {makeField({...o,disabled:formik.isSubmitting,value:formik.values[o.name],onChange:formik.handleChange})}
    </React.Fragment>)}

    actions={{
      children:<Button
        variant="text"
        color='secondary'
        disabled={formik.isSubmitting}
        onClick={formik.submitForm}
        fullWidth
        size='small'
        children={
          formik.isSubmitting?
          <>Generating <Progress.Circular color='secondary'/></>
          :'Generate'
        }
      />
    }}

    collapse={{
      children:(data||err)&&<Box sx={{padding:'16px 8px'}}>
        <Typo>{data||err}</Typo>
      </Box>
    }}
  />

  return <Box
    sx={{padding:'16px 0px'}}
    children={_card}
  />
}
