import * as React from 'react';
import CircularProgressMui,{CircularProgressProps} from '@mui/material/CircularProgress';


const Circular=React.forwardRef((props:CircularProgressProps,ref):React.Element<typeof CircularProgressMui>=>
  <CircularProgressMui {...props} ref={ref}/>)

Circular.defaultProps={
  color:'secondary',
  size:11,
  disableShrink:true,
  sx:{
    marginLeft:'4px',
    marginBottom:'3px'
  },
}

export default Circular

// const Circular       =<Progress.circular disableShrink color='secondary' size={11} sx={{marginLeft:'4px',marginBottom:'3px'}}/>


// export default function CircularProgress(props:CircularProgressProps){
//   return <CircularProgressMui {...props}/>
// }
